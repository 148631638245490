<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";



 import vue2Dropzone from "vue2-dropzone";
 import "vue2-dropzone/dist/vue2Dropzone.min.css";

import ProgressDialog from './components/progress_dialog'

import { getPMApi } from '@/api/pm'


/**
 * Setup PM  component
 */
export default {
  page: {
    title: "Add Rent Split",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations() {

      return {
      

      }
    

  },


  methods: {

    formSubmit() {

      let data = {
        pm_list: []
      }
      let data_error = false

      if (data_error == true) {
        this.has_data_error = true
        return
      }

      this.$bvModal.show('ProgressDiloag')
      this.listingData.map(e => {
        data.pm_list.push(...e.ap_list)
      })
      getPMApi().post_pm_ap(data).then(res => {
        this.$bvModal.hide('ProgressDiloag')

        if (res.errCode == 0) {
          this.$alertify.message("Post AP  successfully");
          this.$router.push({ name: 'pm-property-list' })
        } else {
          this.$alertify.error(" Post  AP failed:" + res.errCode + ' msg:' + res.msg);
        }
      })

    },


    removePMEntry(pm_obj, item) {
      let pm = pm_obj
      let pm_ap = pm.ap_list.find(e => e.tmp_id == item.tmp_id)


      pm.ap_list = pm.ap_list.filter(e => e.tmp_id != item.tmp_id)

      if (pm_ap.type == 'AGENT1') {
        pm.placement_fee_amount -= Number(item.amount)
      } else if (pm_ap.type == 'AGENT') {
        pm.management_fee_amount -= Number(item.amount)
      } else if (pm_ap.type == 'NR4') {
        pm.nr4_amount -= Number(item.amount)
      } else if (pm_ap.type == 'OWNER') {
        pm.owner_amount -= Number(item.amount)
      }


      this.update_pm_balance(pm)
      if (pm.ap_list.length <= 0) {
        this.listingData = this.listingData.filter(e => e.pm_id != pm_obj.pm_id)
      }

    },




    onListUploaded(data, resp) {
      (data);
      if (resp.errCode == 0) {

        this.listingData = []
        this.listingData.push(...resp.data.list)

        this.listingData.map(e => {
          e.management_fee_amount = e.mf_amount + e.mf_gst_amount
          e.owner_amount = e.bto_amount
          e.placement_fee_amount = e.pf_amount + e.pf_gst_amount
          e.ap_list = e.je_list
          this.update_pm_balance(e)
        })


        if (resp.data.err != 0) {
          this.has_data_error = true
          this.data_err_msg = resp.data.note
        }

        // this.total_amount += Number(r.amount)

      } else {
        this.$alertify.error(" Upload  AP failed:" + resp.errCode + " msg:" + resp.msg);
        this.has_data_error = true
        this.data_err_msg = resp.msg
      }
    },



    update_pm_balance(pm) {

      pm.ending_balance = pm.balance_available - pm.management_fee_amount - pm.nr4_amount - pm.owner_amount - pm.placement_fee_amount
      if (pm.ending_balance <= 0) {
        pm._rowVariant = 'danger'
      } else {
        pm._rowVariant = ''
      }
    }



  },

  data() {
    return {
      title: "Post PM AP",
      items: [
        {
          text: "RTA List",
          href: "/pm/property_list",
        },
        {
          text: "Post PM AP",
          active: true,
        },
      ],


      has_data_error: false,
      file: {},

      ap_type: 'AGENT',


      temp_id: 12001,
      pm_month: '',

      remarks: '',
      listingData: [],
      searched_pm_data: [],
      dropzoneOptions: {
        url: "/AwsFileUpload/s3_upload_multi",
        thumbnailWidth: 150,
        maxFilesize: 50,
      },

      total_amount: 0
    };
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,

    ProgressDialog,

  },
  mounted() {
  },

  watch: {
  

  }


};
</script>



<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal centerd id="ProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close
      no-close-on-backdrop no-close-on-esc>
      <ProgressDialog />
    </b-modal>

    <div class="row">


      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">


            <form class="needs-validation" @submit.prevent="formSubmit">

              <div class="row">
                <label class="form-label">Upload Template</label>
              </div>
              <hr class="mt-1" >
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">


                        <vue-dropzone id="expense-dropzone" :use-custom-slot="true"
                          :options="{ url: '/adm/pm/form/update_post_ap_form', maxFilesize: 10, maxFiles: 1, paramName: 'form', thumbnailHeight: 80, thumbnailWidth: 80, addRemoveLinks: true }"
                          @vdropzone-success="onListUploaded">
                          <div>
                            <i class="display-4 text-muted "></i>
                            <h4>Upload file to here.</h4>
                          </div>
                        </vue-dropzone>

                  </div>
                </div>
              </div>

              <div class="row">
                <b-alert show variant="danger" v-if="has_data_error">{{ data_err_msg }}</b-alert>
              </div>


              <div class="row">
                <label class="form-label">List </label>
              </div>
              <hr class="mt-1">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="d-flex flex-wrap gap-2 justify-content-start d-flex align-items-left">
                    <b-button type="submit" class="btn-primary" variant="primary" :disabled="listingData.length <= 0">Submit</b-button>
                    <b-button variant="soft-light" type="button"
                      @click="$router.push({ name: 'pm-property-list' })">Cancel</b-button>
                     
                  </div>
                </div>
              </div>

              <div data-simplebar>
                <div class="table-responsive">
                  <div class="table align-middle table-nowrap">
                    <b-table :items="listingData"
                      :fields="['Code', 'EndingBalance', 'ManagementFee', 'PlacementFee', 'NR4', 'BalanceToOwner', 'NewEndingBalance']"
                      thead-class="bg-light" :head-variant="'light'" hover show-empty responsive="sm" 
                      :per-page="listingData.length" class="table-check">
                      <template #cell(Code)="data">
                        <feather :type="data.detailsShowing ? 'chevron-down' : 'chevron-right'"
                          @click="data.toggleDetails">
                        </feather>
                        {{ data.item.pm_id }}
                      </template>
                      <template #cell(EndingBalance)="data">
                        ${{ data.item.balance_available.toLocaleString() }}
                      </template>

                      <template #cell(ManagementFee)="data">
                        ${{ data.item.management_fee_amount.toLocaleString() }}
                      </template>
                      <template #cell(PlacementFee)="data">
                        ${{ data.item.placement_fee_amount.toLocaleString() }}
                      </template>

                      <template #cell(NR4)="data">
                        ${{ data.item.nr4_amount.toLocaleString() }}
                      </template>
                      <template #cell(BalanceToOwner)="data">
                        ${{ data.item.owner_amount.toLocaleString() }}
                      </template>
                      <template #cell(NewEndingBalance)="data">
                        ${{ data.item.ending_balance.toLocaleString() }}
                      </template>

                      <template v-slot:row-details="row">
                        <div class="table align-middle table-nowrap" style="margin-left:30px;">
                          <b-table :items="row.item.ap_list"
                            :fields="['Type', 'PostDate', 'Amount', 'Gst', 'Total', 'Action']" responsive="sm"
                            :per-page="row.item.ap_list.list ? row.item.ap_list.length : 0"
                            class="table-check col-md-10">

                            <template #cell(Type)="data">
                              {{ data.item.type }}
                            </template>
                            <template #cell(PostDate)="data">
                              {{ data.item.post_date }}
                            </template>

                            <template #cell(Amount)="data">
                              ${{ data.item.amount.toLocaleString() }}
                            </template>
                            <template #cell(Gst)="data">
                              ${{ data.item.gst.toLocaleString() }}
                            </template>
                            <template #cell(Total)="data">
                              ${{ data.item.total.toLocaleString() }}
                            </template>
                            <template #cell(Action)="data">
                              <b-dropdown variant="white" right toggle-class="btn-link text-dark shadow-none">
                                <template v-slot:button-content>
                                  <i class="uil uil-ellipsis-h"></i>
                                </template>
                                <b-dropdown-item @click="removePMEntry(row.item, data.item)">Remove</b-dropdown-item>
                              </b-dropdown>

                            </template>

                          </b-table>
                        </div>
                      </template>

                    </b-table>
                  </div>
                </div>
              </div>

            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
